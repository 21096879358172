import AvdAnntR from "../../Images/Team/Adv_Anant_Randive.JPG";
import AvdSachinR from "../../Images/Team/Avd_Sachin_Randive.jpg";
import AvdArchanaC from "../../Images/Team/Adv_Archana.jpeg";
import AvdAshaW from "../../Images/Team/Adv_Asha_Walunjkar.JPG";
import AvdIreshD from "../../Images/Team/Adv_Iresh_Desai.JPG";
import AvdKunalC from "../../Images/Team/Adv_Kunal_Chugh.JPG";
import AvdRohanR from "../../Images/Team/Adv_Rohan_Randive.JPG";
import AvdSavitaS from "../../Images/Team/Adv_Savita_Saxena.JPG";
import AvdShubhamK from "../../Images/Team/Adv_Shubham_Kamble.JPG";

export const TeamMembers = {
        foundingTeam : [
            {
              memberImg:
              AvdAnntR,
              name: "Adv. Anant S. Randive",
              designation: "B.S.L, LL.B, LL.M",
            },
            {
              memberImg:
              AvdSachinR,
              name: "Adv. Sachin S. Randive",
              designation: "B.A, LL.B",
            },
            
          ],
          coreTeam : [
            {
              memberImg:
              AvdRohanR,
              name: "Adv. Rohan Randive",
              designation: "B.S.L, LL.B",
            },
            {
              memberImg:
              AvdSavitaS,
              name: "Adv. Savita Saxena",
              designation: "B.Sc, LL.B, LL.M",
            },
            {
              memberImg:
              AvdShubhamK,
              name: "Adv. Shubham Kamble",
              designation: "B.COM, LL.B",
            },
            {
              memberImg:
              AvdIreshD,
              name: "Adv. Iresh Desai",
              designation: "M.A, LL.B",
            },
            {
              memberImg:
              AvdAshaW,
              name: "Adv. Asha Walunj",
              designation: "B.A, LL.B",
            },
            {
              memberImg:
              AvdArchanaC,
              name: "Adv. Archana Chugh",
              designation: "M.B.A, M.Sc, LL.B",
            },
            {
              memberImg:
              AvdKunalC,
              name: "Adv. Kunal Chugh",
              designation: "B.E(IT), LL.B",
            },
          ]
}