import React, { useState, useEffect } from 'react';
import '../../../Static/css/DisclaimerModal.css';
import { Modal } from 'react-bootstrap';


const DisclaimerModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const isDisclaimerAccepted = localStorage.getItem('isDisclaimerAccepted');
    if (!isDisclaimerAccepted) {
      setIsVisible(true);
    }
    else{
        setIsVisible(true);
    }
  }, []);

  const handleAgree = () => {
    localStorage.setItem('isDisclaimerAccepted', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal show={isVisible} centered>
      <Modal.Header>
        <Modal.Title>DISCLAIMER:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
        <p >
        The rules of the Bar Council of India prohibit law firms from soliciting work or advertising in any manner. By clicking on 'I AGREE', the user acknowledges that:
        </p>
        <ul>
          <li>The user wishes to gain more information about Randive & Randive Associates, its practice areas, and its attorneys, for his/her own information and use.</li>
          <li>The information is made available/provided to the user only upon his/her specific request. Any information obtained or material downloaded from this website is done so at the user's own volition. The transmission, receipt, or use of this site is not intended to, and will not, create any lawyer-client relationship.</li>
          <li>None of the information contained on the website constitutes legal opinion or legal advice.</li>
          <li>Randive & Randive Associates is not liable for any consequences arising from actions taken by the user relying on material/information provided on this website. Users with legal issues must seek independent legal advice.</li>
        </ul>
        </div>
        <button onClick={handleAgree}>I Agree</button>
      </Modal.Body>
    </Modal>
  );
};

export default DisclaimerModal;
