import React, { useEffect, useRef, useState } from "react";
import OIG2_ACHI_1 from "../../Images/OIG2_ACHI_1.svg";
import AwardCard from "./AwardCard";

const awards = [
  {
    title: "Judge",
    date: "29 & 30 April 2024",
    description: "At 3rd National Moot Court Competition, 2024 organised by Dr DY Patil Law College, Pimpri, Pune.",
    image: "path/to/award1.jpg",
  },
  {
    title: "Lokmat - The Great Legal Minds",
    date: "December 16, 2023",
    description: "Awarded for brilliant achievement in jurisprudence, exemplary advocacy, integrity and commitment to justice.",
    image: "path/to/award1.jpg",
  },
  {
    title: "Judge",
    date: "19 October 2019",
    description: "At 1st National Moot Court Competition, 2019 organised by Dr DY Patil Law College, Pimpri, Pune.",
    image: "path/to/award1.jpg",
  },
];

const AchivementsCard = () => {

  return (
    <>
      <div className="chromebook-intro achievement">
      {/* <div className="stars"></div> */}
        <h1 className="headingC" style={{color: '#fff'}}>Achievements</h1>
        <br />
        <div className="d-flex flex-column flex-lg-row justify-content-lg-center panel">
          <div className="awards-container">
            {awards.map((award, index) => (
              <AwardCard
                key={index}
                title={award.title}
                date={award.date}
                description={award.description}
                // image={award.image}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AchivementsCard;
