import React from "react";
import { TeamMembers } from "../Team/TeamMembers";
const TeamM = () => {
  
  return (
    <>
      <div className="chromebook-intro">
        <h2 className="headingC">Meet The <span style={{color: "#ff433a"}}>Team</span></h2>
        <br />
        <div className="subTeamHeaderAlign">
            <div className="vertLineTeamHeader"></div>
            <h5 className="subTeamHeader">Founding Team</h5>
        </div>
        <br />
        <br />
          <div className="d-flex flex-column flex-lg-row justify-content-lg-center"  style={{flexWrap: 'wrap'}}>
            {TeamMembers.foundingTeam.map((mem) => {
              return (
                <>
                  <div className="columnTeam1" key={mem.service}>
                    <div className="team-9">
                      <div className="team-img">
                        <img src={mem.memberImg} alt="Team Image" />
                      </div>
                      <div className="team-content">
                        <h2>{mem.name}</h2>
                        <h3>{mem.designation}</h3>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <br />
          <div className="subTeamHeaderAlign">
            <div className="vertLineTeamHeader"></div>
            <h5 className="subTeamHeader">Core Team</h5>
          </div>
          <br />
          <br />
          <div className="d-flex flex-column flex-lg-row justify-content-lg-center" style={{flexWrap: 'wrap'}}>
            {TeamMembers.coreTeam.map((mem) => {
              return (
                <>
                  <div className="columnTeam2" key={mem.service}>
                    <div className="team-10">
                      <div className="team-img">
                        <img src={mem.memberImg} alt="Team Image" />
                      </div>
                      <div className="team-content">
                        <h2>{mem.name}</h2>
                        <h3>{mem.designation}</h3>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
    </>
  );
};

export default TeamM;
