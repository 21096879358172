import { useEffect } from 'react';

const useIntersectionObserver = (className) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.remove('card-exit');
            entry.target.classList.add('card-visible');
          } else {
            entry.target.classList.remove('card-visible');
            entry.target.classList.add('card-exit');
          }
        });
      },
      {
        threshold: 0.1, // Adjust this threshold as needed
      }
    );

    const elements = document.querySelectorAll(className);
    elements.forEach((element) => observer.observe(element));

    return () => {
      elements.forEach((element) => observer.unobserve(element));
    };
  }, [className]);
};

export default useIntersectionObserver;
