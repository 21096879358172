import React, { useRef } from "react";
import { Card } from "react-bootstrap";

const ServCard = {
  width: '18rem', 
  height: '8rem',
  backgroundColor: '#f2f5fa',
  borderRadius: '10px',
  borderColor: 'none',
  textAlign: 'left',
}
const ServicesCard = () => {
  const cardRef = useRef(null);
  let services = [{"service": "Business", "text": "Providing comprehensive legal services for businesses of all sizes"},
  {"service": "Corporate", "text": "Empowering businesses to achieve their goals with the best legal expertise"},
  {"service": "Finance", "text": "Providing legal counsel for informed financial decisions"},
  {"service": "Banking", "text": "Navigating complex banking regulations and disputes"},
  {"service": "Agriculture", "text": "Protecting the legal interests of farmers and agribusinesses"},
  {"service": "Real Estate", "text": "Guiding you through all aspects of property transactions"},
  {"service": "Consumer Protection", "text": "Safeguarding your rights in the marketplace"},
  {"service": "Inheritance & Matrimonial ", "text": "Navigating complex legal matters related to family and estates"},
  {"service": "Criminal Litigation", "text": "Defending your rights and navigating the criminal justice system"},
  {"service": "Environmental protection", "text": "Advocating for a sustainable future through legal expertise"},
  {"service": "Deeds and Agreements", "text": "Drafting and reviewing contracts to protect your interests"},
  {"service": "Appellate at High and Supreme Court", "text": "Representing clients in appeals before the highest courts"},
]

  return (
    <>
    <div className="chromebook-intro-srv">
      <h1 className="headingC">Our Practices</h1>
          <br/>
          <br/>
            <div className="d-flex flex-column flex-lg-row justify-content-lg-center panel">
              {
                services.map((serv) => {
                  return (
                          <>
                          <Card ref={cardRef} style={ServCard} className="card-hidden card-box" key={serv}>
                              <Card.Body>
                                  <Card.Subtitle className="mb-3" style={{fontSize: '18px'}}>{serv.service}</Card.Subtitle>
                                  <Card.Text style={{fontSize: '14px'}}>
                                    {serv.text}
                                  </Card.Text>
                              </Card.Body>
                          </Card>
                          </>
                  )
                })
              }
            
                
            </div>
      </div>
    </>
  );
};

export default ServicesCard;
