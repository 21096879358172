// src/components/PrivacyPolicy.js
import React, { useEffect } from 'react';
import '../../../Static/css/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  useEffect(()=> {
    window.scrollTo(0, 0);
  })
  return (
    <div className="chromebook-intro-srv">
      <div className="container-fluid">
        {/* <div className="d-flex flex-column flex-lg-row justify-content-lg-center panel"> */}
          <div className="privacy-policy-content">
          <h1 className='headingC'>Privacy Policy</h1>
            <br/>
            <p>Randive & Randive Associates ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website https://rrassociates.in/. By accessing the Site, you agree to the terms of this Privacy Policy.</p>
            <h2>1. Information We Collect</h2>
            <h3>Personal Data</h3>
            <p>We may collect personally identifiable information, including but not limited to:</p>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Address</li>
              <li>Any other information you voluntarily provide</li>
            </ul>
            <h3>Usage Data</h3>
            <p>We may also collect information such as:</p>
            <ul>
              <li>IP address</li>
              <li>Browser type and version</li>
              <li>Pages you visit</li>
              <li>Time and date of your visit</li>
              <li>Time spent on pages</li>
              <li>Unique device identifiers</li>
              <li>Diagnostic data</li>
            </ul>
            <h2>2. Use of Your Information</h2>
            <p>We use your information to:</p>
            <ul>
              <li>Operate and maintain the Site</li>
              <li>Improve and personalize the Site</li>
              <li>Understand and analyze Site usage</li>
              <li>Develop new services and features</li>
              <li>Communicate with you</li>
              <li>Process transactions and send related information</li>
              <li>Prevent fraud</li>
            </ul>
            <h2>3. Sharing Your Information</h2>
            <p>We may share your information:</p>
            <ul>
              <li>With third-party service providers to perform functions and provide services to us, under confidentiality agreements</li>
              <li>To comply with legal obligations or in response to lawful requests</li>
            </ul>
            <h2>4. Security of Your Information</h2>
            <p>We implement administrative, technical, and physical security measures to protect your information. However, no security system is impenetrable, and we cannot guarantee complete security.</p>
            <h2>5. Your Data Protection Rights</h2>
            <p>Depending on your location, you may have the following rights:</p>
            <ul>
              <li>Access your personal data</li>
              <li>Rectify inaccurate data</li>
              <li>Erase your data</li>
              <li>Restrict or object to data processing</li>
              <li>Data portability</li>
            </ul>
            <p>To exercise these rights, please contact us using the information below. We will respond within one month.</p>
            <h2>6. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy periodically. Changes are effective upon posting on this page. Please review this Privacy Policy regularly.</p>
            <h2>7. Contact Us</h2>
            <p>If you have any questions or concerns, please contact us:</p>
            <ul>
              <li>Email: randiveandrandiveassociates@gmail.com</li>
              <li>Phone: +91 20-29529771</li>
            </ul>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
