import React, { useEffect } from "react";
import useIntersectionObserver from "../UseIntersectionObserver";
import SubGallery from "./SubGallery";
import Footer from "../Footer";
import { Helmet } from "react-helmet";

const Gallery = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useIntersectionObserver(".card-hidden");
  return (
    <>
    <Helmet>
        <title>Gallery</title>
        <meta name="description" content="Office, Team & News" />
        <meta name="keywords" content="law, lawyer, law firm, legal services" />
        <link rel="canonical" href="/gallery"/>
    </Helmet>
      <div className="newGrid_container">
        <div className="banner media-banner">
          <div className="banner-content">
            {/* <h1>Randive & Randive <br/>Associates</h1> */}
          </div>
        </div>
        <div className="chromebook-intro-srv" style={{ marginTop: "50px" }}>
          <SubGallery />
        </div>
      </div>
    </>
  );
};

export default Gallery;
