import emailjs, { init } from "@emailjs/browser";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";

const ContactForm = ({ handleClose }) => {
  const [msg, setMsg] = useState();
  const formData = useRef();

  useEffect(() => emailjs.init("3PDTKoILBYOnZvbnE"), []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_sxeuw5n";
    const templateId = "template_9eqfhf8";

    try {
      emailjs.sendForm(serviceId, templateId, formData.current);
      setMsg("Your enquiry recorded, we'll connect with you soon!");
      setTimeout(() => {
        setMsg("");
        document.getElementById("contact-form").reset();
        }, 2000);
    } catch (error) {
      setMsg("Something went wrong!");
    } finally {
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} ref={formData} id="contact-form">
        <span style={{ fontSize: "13px", color: "#fc8c03" }}>{msg}</span>
        <Form.Group controlId="formName" className="mb-2">
          <Form.Control
            type="text"
            name="from_name"
            placeholder="Enter your name"
            value={formData.name}
            required
          />
        </Form.Group>
        <Form.Group controlId="formPhone" className="mb-2">
          <Form.Control
            type="text"
            name="from_phone"
            placeholder="Enter your phone"
            value={formData.phone}
            required
          />
        </Form.Group>
        <Form.Group controlId="formEmail" className="mb-2">
          <Form.Control
            type="email"
            name="from_email"
            placeholder="Enter your email"
            value={formData.email}
            required
          />
        </Form.Group>
        <Form.Group controlId="formMessage" className="mb-2">
          <Form.Control
            as="textarea"
            rows={3}
            name="from_message"
            placeholder="Enter your message"
            value={formData.message}
            required
          />
        </Form.Group>
        <Button
          className="mt-3"
          style={{
            backgroundColor: "#ff433a",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            color: "#fffff",
          }}
          type="submit"
        >
          Send
        </Button>
      </Form>
    </>
  );
};

export default ContactForm;
