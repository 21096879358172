import React from "react";

const ClientsChoiceCard = ({ serviceTxt, serviceInfo }) => {
  
    return (
        <div className="hweywy-item">
          <span className="sub-headingL">{serviceTxt}</span>
          <br />
          <br />
          <p>{serviceInfo}</p>
        </div>
    );
  };

  export default ClientsChoiceCard;