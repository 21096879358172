import React from 'react';

const AwardCard = ({ title, date, description, image }) => {
  return (
    <div className="award-card card-hidden">
      {/* <img src={image} alt={`${title} certificate`} className="award-image" /> */}
      <div className="award-content">
        <h3 className="award-title">{title}</h3>
        <p className="award-date">{date}</p>
        <p className="award-description">{description}</p>
      </div>
    </div>
  );
};

export default AwardCard;
