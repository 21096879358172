import React from "react";

const GalleryImageCard = ({imgUrl, imgTitle}) => {
    
    return (
        <>
            <div className="award-card card-hidden m-2" style={{ width: '18rem', height: '12rem', textAlign: 'left' }}>
                    <img style={{fontSize: '13px', aspectRatio: '3/2'}} src={imgUrl}/>
                    {/* <hr/> */}
                    {/* <Card.Subtitle className="mb-2 text-muted">{imgTitle}</Card.Subtitle> */}
            </div>
            
        </>
    )
}

export default GalleryImageCard;