import React from "react";

const FirmIntro = () => {
    return <>
        <div className="banner main-banner">
            <div className="banner-content">
              <h1>
                Randive & Randive <br />
                Associates
              </h1>
            </div>
          </div>
          <div className="columns-12">
            <div className="chromebook-intro">
              <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <h1 className="headingL">Our Foundation</h1>
                    <br />
                    <p className="content-p">
                      Randive and Randive Associates is a premier law firm
                      providing a comprehensive range of legal services and
                      solutions for clients. The firm was established in 2015
                      with the intent of making the availability of legal
                      services more efficient, cost-effective and time-bound and
                      has thereafter earned a reputation as one of the leading
                      law firms in Pune and Aurangabad.
                    </p>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <h1 className="headingL">Objective</h1>
                    <br />
                    <p className="content-p">
                      The objective of the firm has always been to provide
                      high-quality legal advice through an efficiently managed
                      professional practice that will facilitate its clients.
                      The firm aspires to be the foremost innovative law firm by
                      building long-term relationships with clients based on
                      reciprocity, trust and the highest standards of
                      professional ethics. This blend of exceptionally trained
                      and experienced individuals coupled with an in-depth
                      knowledge of the regional business, legal and political
                      scenes, places us in a unique position to best address our
                      clients’ most demanding issues and critical disputes.
                    </p>
                  </div>
              </div>
            </div>
          </div>
    </>
}

export default FirmIntro;