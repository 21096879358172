
import React, { useEffect } from "react";
import LogoImg from "../../Website/Images/Logo_2.png";
import ContactForm from "./Contact/ContactForm";
import { Link } from "react-router-dom";

const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={LogoImg} alt="Randive & Randive Associates" className="footer-brand-logo" />
        </div>
        <div className="footer-sections">
          <div className="footer-contact mt-5">
            <h3>Get In Touch</h3>
            <ContactForm />
          </div>
          <div className="footer-addresses">
          
            <div className="footer-address">
              <address>
                <b>RANDIVE & RANDIVE ASSOCIATES (PUNE)</b><br />
                <br/>
                <div style={{fontSize: '14px'}}>
                  101, Ritika Residency, Nisarg-pooja Society, Mankar Chowk, Wakad<br />
                  City: Pune, State: Maharashtra<br />
                  Email: anantrandive999@gmail.com, randiveandrandiveassociates@gmail.com<br />
                  Phone: +91 20-29529771, +91 9881800999, +91 9503111999
                </div>
              </address>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.6368534412272!2d73.77130987406596!3d18.590403767039707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c07800c537bf%3A0x630b8d5fdf6fd411!2sRandive%20law%20associates!5e0!3m2!1sen!2sin!4v1716638572076!5m2!1sen!2sin"
                width="100%"
                height="150"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Pune Office Location"
                target="/blank"
              ></iframe>
            </div>
            <div className="footer-address">
              <address>
                <b>RANDIVE & RANDIVE ASSOCIATES (AURANGABAD)</b><br />
                <br/>
                <div style={{fontSize: '14px'}}>
                  Flat No. 2, Plot. 49, Gajanan Complex, Gajanan Mandir Road, Vijay Nagar<br />
                  City: Aurangabad, State: Maharashtra<br />
                  Email: adv.sachinrandive@gmail.com, randiveandrandiveassociates@gmail.com<br />
                  Phone: +91 9767844444, +91 8087661986
                </div>
              </address>
              
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3752.2521954317904!2d75.352087!3d19.87157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTnCsDUyJzE3LjciTiA3NcKwMjEnMDcuNSJF!5e0!3m2!1sen!2sin!4v1716639815795!5m2!1sen!2sin"
                width="100%"
                height="150"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Aurangabad Office Location"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="footer-social mb-3">
          {/* <a href="#" aria-label="Facebook">
            <i className="fa fa-facebook"></i>
          </a>
          <a href="#" aria-label="Twitter">
            <i className="fa fa-twitter"></i>
          </a> */}
          <a href="https://www.linkedin.com/in/randive-and-randive-associates-3a2685270" target="_blank" aria-label="LinkedIn" rel="noreferrer">
            <i className="fa fa-linkedin" style={{color: '#ff433a'}}></i>
          </a>
          <a href="https://www.instagram.com/randive.associates?igsh=MXN6YWJjYmJxbWczMw==" target="_blank" aria-label="Instagram" rel="noreferrer">
            <i className="fa fa-instagram" style={{color: '#ff433a'}}></i>
          </a>
        </div>
      
      </div>
      <div className="footer-social" style={{backgroundColor: '#515151', width: '100%', color: '#fff'}}>
        <p className="footer-company-name" >&copy; {currentYear} Randive & Randive Associates</p>
        <Link className="nav-link" to='/privacy-policy' style={{ color: '#fff', textDecoration: 'underline'}}>Privacy Policy</Link>
      </div>
    </footer>
  );
};

export default Footer;
