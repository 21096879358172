
import React from 'react';
import { Modal } from 'react-bootstrap';

const ImageModal = ({ show, handleClose, imageUrl }) => {
  return (
    <Modal show={show} onHide={handleClose} centered style={{width: '100%'}}>
      <Modal.Body>
        <img src={imageUrl} alt="Expanded" className="img-fluid" />
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
