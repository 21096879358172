import React from 'react';
import { Modal } from 'react-bootstrap';
import ContactForm from './ContactForm';


const ContactModal = ({ show, handleClose }) => {
  

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Get In Touch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContactForm handleClose={handleClose}/>
      </Modal.Body>
    </Modal>
  );
};

export default ContactModal;
