import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ServicesCard from "./ServicesCard";
import useIntersectionObserver from "../UseIntersectionObserver";
import Footer from "../Footer";

const FirmServices = () => {
  useEffect(()=> {
    window.scrollTo(0, 0);
  })
  useIntersectionObserver(".card-hidden")
  return (
    <>
      <Helmet>
        <title>Services</title>
        <meta name="description" content="Randive & Randive Associates - Services" />
        <meta name="keywords" content="law, lawyer, law firm, legal services" />
        <link rel="canonical" href="/services"/>
      </Helmet>
      <ServicesCard />
    </>
  );
};

export default FirmServices;
