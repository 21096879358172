import React from "react";
import { Link } from "react-router-dom";
import LogoImg from "../../Images/Logo_2.png";

const Navbar = () =>{
     
      const scrollToTop = () => {
        window.scroll({
            top: document.body.offsetTop,
            left: 0, 
            behavior: 'smooth',
          });
      }

      const scrollToFooter = () => {
        const element = document.getElementById("footer-section")
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest'});
      }
    return <>
        <nav className="navbar navbar-expand-lg fixed-top navbar-light head-top">
      <Link className="navbar-brand" to="/">
        <img src={LogoImg} className='Hbrand-logo' alt="Brand Logo" />
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/aboutus" onClick={scrollToTop}>
              About us
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/services">
              Services
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/gallery">
              Gallery
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" onClick={scrollToFooter}>
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
    </>
}

export default Navbar;