import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
const videoData = [
  {
    id: "swbHPCF_JP0", // Replace with your YouTube video ID
    title: "REVENUE OFFICERS, POWERS & DUTIES MLRC- BY ADV. ANANT RANDIVE",
  },
  {
    id: "dDU1DauaU18", // Replace with your YouTube video ID
    title: "RECORD OF RIGHTS MLRC- BY ADV. ANANT RANDIVE",
  },
  {
    id: "UgMZkpPdZLY", // Replace with your YouTube video ID
    title: "PROVISION FOR BAIL & BOND- BY ANANT RANDIVE",
  },
];

function VideoList() {
  const titleLength = 30;
  return (
    <div className="chromebook-intro-srv">
      <h1 className="headingC">Videos</h1>
      <br />
      <br />
      <div className="d-flex flex-column flex-lg-row justify-content-lg-center panel">
        {videoData.map((video) => (
          <div key={video.id} className="video-item">
            <iframe
              src={`https://www.youtube.com/embed/${video.id}?autoplay=0&controls=1&rel=0`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              loading="lazy"
              title={video.title}
            />
            <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{video.title}</Tooltip>}
          >
            <div className="video-title">
            {video.title.length > titleLength
              ? video.title.substring(0, titleLength) + '...'
              : video.title}
            </div>
          </OverlayTrigger>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VideoList;
