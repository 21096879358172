import React from "react";
import ClientsChoiceCard from "./ClientChoiceCard";
import useIntersectionObserver from "../UseIntersectionObserver";

const ClientsChoice = () => {
  let services = [
    {
      service: "Expertise & Track Record",
      info:
        "Highlighting our team's mastery and demonstrated success in relevant practice areas",
    },
    {
      service: "Client-Centred Approach",
      info:
        "Prioritizing clear communication, prompt responsiveness, and understanding our clients' needs",
    },
    {
      service: "Representation",
      info:
        "Delivering appropriate resolutions while considering cost-effectiveness and time efficiency",
    },
  ];
  var top = 9;
  var counter = 0;

  useIntersectionObserver(".card-hidden")
  return (
    <>
      <div className="chromebook-intro">
        <div className="row">
          <div className="col-md-5 clt-pr pt-3">
            <div className="d-flex flex-column flex-lg-row justify-content-lg-center panel">
              <p>
                <b>
                  <i>
                    <span style={{color: '#ff433a', fontSize: '30px'}}>&ldquo;</span>Bringing two decades of seasoned expertise to the
                    legal arena
                    <span style={{color: '#ff433a', fontSize: '30px'}}>&rdquo;</span>
                  </i>
                </b>
              </p>
              <p>
                Serving over <b>500+</b> clients throughout Maharashtra. The
                firm has the ability to assist clients in all districts of
                Maharashtra and is rapidly expanding its services to litigation
                in the Courts and Tribunals in other States of India.
              </p>
            </div>
          </div>
          <div className="col-md-7 pt-3">
            <div className="d-flex flex-column flex-lg-row justify-content-lg-center panel">
              {services.map((serv, index) => 
              (    
                  <div className="kskkwy-card card-hidden" key={index}>
                    <ClientsChoiceCard serviceTxt={serv.service} serviceInfo={serv.info} />
                  </div>
              )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientsChoice;
