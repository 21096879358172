import React, { useEffect, useState } from "react";
import AchivementsCard from "../Achivements/AchivementsCard";
import TeamM from "../Team/TeamM";
import VideoList from "../Videos/ShowVideos";
import ClientsChoice from "../Cards/ClientsChoice";
import { Helmet } from "react-helmet";
import FirmIntro from "./FirmIntro";
import FirmServices from "../Services/FirmServices";

const HomeIntro = () => {
  const [yrsOfExp, setYrsOfExp] = useState("");
  useEffect(() => {
    getYrsOfExp();
    window.scrollTo(0, 0);
  });

  const getYrsOfExp = () => {
    var yrsOfExp = new Date().getFullYear() - "2015";
    if (yrsOfExp < 9) {
      yrsOfExp = "0" + yrsOfExp;
    }
    setYrsOfExp(yrsOfExp);
  };

  return (
    <>
      <div>
      <Helmet>
        <title>Randive & Randive Associates</title>
        <meta name="description" content="Randive & Randive Associates" />
        <meta name="keywords" content="law, lawyer, law firm, legal services" />
        <link rel="canonical" href="/"/>
        <link rel="canonical" href="/aboutus"/>
      </Helmet>
        <div className="newGrid_container" id="about-section">
          <FirmIntro />
          <div className="columns-12">
            <ClientsChoice />
          </div>
          <div className="columns-12">
            <AchivementsCard />
          </div>
          <div className="columns-12" id="services-section">
            <FirmServices />
          </div>
          <div className="columns-12 wrapper">
            <VideoList />
          </div>
          <div className="columns-12">
            <TeamM />
          </div>
          
        </div>
      </div>
    </>
  );
};

export default HomeIntro;
