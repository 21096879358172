
import React, { useState } from "react";
import GalleryImageCard from "../Cards/GalleryImageCard";
import {
  galleryCardCertficatesImgs,
  galleryCardImgs,
  galleryCardNewsImgs,
  galleryCardOfficeImgs,
} from "./gallery-group-img-config";
import ImageModal from "./ImageModal";

const SubGallery = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const handleClose = () => setShowModal(false);
  
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };
  return (
    <>
    <div className="container-fluid">
      <ImageModal show={showModal} handleClose={handleClose} imageUrl={selectedImage} />
        <h1 className="headingC">Office & Team Members</h1>
        <hr />
        <div className="d-flex flex-column flex-lg-row justify-content-lg-center panel">
          {Object.keys(galleryCardOfficeImgs).map((detailKey) => {
            return (
              <div onClick={() => handleImageClick(galleryCardOfficeImgs[detailKey].imgUrl)}>
              <GalleryImageCard
                imgUrl={galleryCardOfficeImgs[detailKey].imgUrl}
                imgTitle={galleryCardOfficeImgs[detailKey].title}
              ></GalleryImageCard>
              </div>
              
            );
          })}
        </div>
      </div>
      <div className="container-fluid" style={{ marginTop: "50px" }}>
        <h1 className="headingC">Awards and Achievements</h1>
        <hr />
        <div className="d-flex flex-column flex-lg-row justify-content-lg-center panel">
          {Object.keys(galleryCardCertficatesImgs).map((detailKey) => {
            return (
              <div onClick={() => handleImageClick(galleryCardCertficatesImgs[detailKey].imgUrl)}>
              <GalleryImageCard
                imgUrl={galleryCardCertficatesImgs[detailKey].imgUrl}
                imgTitle={galleryCardCertficatesImgs[detailKey].title}
              ></GalleryImageCard>
              </div>
              
            );
          })}
        </div>
      </div>
      <div className="container-fluid" style={{ marginTop: "50px" }}>
        <h1 className="headingC">Events</h1>
        <hr />
        <div className="d-flex flex-column flex-lg-row justify-content-lg-center panel">
          {Object.keys(galleryCardImgs).map((detailKey) => {
            return (
              <div onClick={() => handleImageClick(galleryCardImgs[detailKey].imgUrl)}>
                <GalleryImageCard
                imgUrl={galleryCardImgs[detailKey].imgUrl}
                imgTitle={galleryCardImgs[detailKey].title}
              ></GalleryImageCard>
              </div>
              
            );
          })}
        </div>
      </div>

      <div className="container-fluid" style={{ marginTop: "50px" }}>
        <h1 className="headingC">News</h1>
        <hr />
        <div className="d-flex flex-column flex-lg-row justify-content-lg-center panel">
          {Object.keys(galleryCardNewsImgs).map((detailKey) => {
            return (
              <div onClick={() => handleImageClick(galleryCardNewsImgs[detailKey].imgUrl)}>
              <GalleryImageCard
                imgUrl={galleryCardNewsImgs[detailKey].imgUrl}
                imgTitle={galleryCardNewsImgs[detailKey].title}
              ></GalleryImageCard>
              </div>
            );
          })}
        </div>
      </div>
      
    </>
  );
};

export default SubGallery;
