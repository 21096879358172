import React, { useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom';
import HomeIntro from './Website/Components/Home/HomeIntro';
import Gallery from './Website/Components/Gallery/Gallery';
import { Button, ButtonGroup } from 'react-bootstrap';
import './Static/css/Navbar.css'; 
import useIntersectionObserver from './Website/Components/UseIntersectionObserver';
import ContactModal from './Website/Components/Contact/ContactModal';
import { Helmet } from 'react-helmet';
import DisclaimerModal from './Website/Components/Cards/DisclaimerModal';
import Navbar from './Website/Components/Home/Navbar';
import FirmServices from './Website/Components/Services/FirmServices';
import Footer from './Website/Components/Footer';
import PrivacyPolicy from './Website/Components/Privacy/PrivacyPolicy';

function App() {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const scrollToFooter = () => {
    const element = document.getElementById("footer-section")
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  useIntersectionObserver(".card-hidden")
  return (
    <div>
      <Helmet>
      <title>Randive & Randive Associates</title>
        <meta name="description" content="Randive & Randive Associates" />
        <meta name="keywords" content="law, lawyer, law firm, legal services" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/"/>
        <link rel="canonical" href="/aboutus"/>
      </Helmet>
      <BrowserRouter>
      <div className="App">
      <DisclaimerModal />
      <Navbar />
        <main className="content">
          <Routes>
            <Route path="/" exact element={<HomeIntro />} />
            <Route path="/aboutus" exact element={<HomeIntro />} />
            <Route path="/gallery" exact element={<Gallery />} />
            <Route path="/services" exact element={<FirmServices />} />
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          </Routes>
        </main>
        <div className="columns-12 wrapper" id="footer-section">
            <Footer />
          </div>
      </div>
      <ButtonGroup
      style={{
        position: 'fixed',
        bottom: '40px',
        right: '20px',
        display: 'flex',
        flexDirection: 'column', // Set flex direction to column
        alignItems: 'center', // Center align items vertically
        zIndex: 10,
      }}
    >
      <Button
        style={{
          backgroundColor: '#ff433a',
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          border: 'none',
          justifyContent: 'center',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          color: '#fff',
          marginBottom: '10px', // Add margin between buttons
        }}
        type="button"
        className="btn btn"
        onClick={handleShow}
      >
        <i className="fa fa-phone fa-lg" aria-hidden="true"></i>
      </Button>
      <Button
        style={{
          backgroundColor: '#ff433a',
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          border: 'none',
          justifyContent: 'center',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          color: '#fff',
        }}
        type="button"
        className="btn btn"
        onClick={scrollToFooter}
      >
        <i className="fa fa-arrow-down" aria-hidden="true"></i>
      </Button>
    </ButtonGroup>


      <ContactModal show={showModal} handleClose={handleClose} />


    </BrowserRouter>
    </div>
  );
}

export default App;
