import Img1 from '../../Images/Gallery/Events/Event_1.jpg'
import Img2 from '../../Images/Gallery/Events/Event_2.jpg'
import Img3 from '../../Images/Gallery/Events/Event_3.jpg'
import Img4 from '../../Images/Gallery/Events/Event_4.jpg'

import News1 from '../../Images/Gallery/News/News_1.jpg'
import News2 from '../../Images/Gallery/News/News_2.jpg'
import News3 from '../../Images/Gallery/News/News_3.jpg'
import News4 from '../../Images/Gallery/News/News_4.jpg'
import News5 from '../../Images/Gallery/News/News_5.jpg'
import News6 from '../../Images/Gallery/News/News_6.jpg'
import News7 from '../../Images/Gallery/News/News_7.jpg'
import News8 from '../../Images/Gallery/News/News_8.jpg'
import News9 from '../../Images/Gallery/News/News_9.jpg'
import News10 from '../../Images/Gallery/News/News_10.jpg'


import Cert1 from '../../Images/Gallery/Certificates/1.png'
import Cert2 from '../../Images/Gallery/Certificates/2.png'
import Cert3 from '../../Images/Gallery/Certificates/3.png'
import Cert4 from '../../Images/Gallery/Certificates/4.png'
import Cert5 from '../../Images/Gallery/Certificates/5.png'
import Cert6 from '../../Images/Gallery/Certificates/6.png'


import Team1 from '../../Images/Team//GroupImages/DSC_2734.JPG'
import Team2 from '../../Images/Team//GroupImages/DSC_2739.JPG'
import Team3 from '../../Images/Team//GroupImages/DSC_2836.JPG'
import Team4 from '../../Images/Team//GroupImages/DSC_2858.JPG'
import Team5 from '../../Images/Team//GroupImages/DSC_2874.JPG'

import OfficeImg1 from '../../Images/Office/IMG-20240331-WA0031.jpg'
import OfficeImg2 from '../../Images/Office/IMG-20240331-WA0032.jpg'
import OfficeImg3 from '../../Images/Office/IMG-20240331-WA0033.jpg'
import OfficeImg4 from '../../Images/Office/IMG-20240331-WA0034.jpg'
import OfficeImg5 from '../../Images/Office/IMG-20240331-WA0035.jpg'
import OfficeImg6 from '../../Images/Office/IMG-20240331-WA0036.jpg'
import OfficeImg7 from '../../Images/Office/IMG-20240331-WA0037.jpg'
import OfficeImg8 from '../../Images/Office/IMG-20240331-WA0038.jpg'
import OfficeImg9 from '../../Images/Office/IMG-20240331-WA0039.jpg'
import OfficeImg10 from '../../Images/Office/DSC_2842.JPG'


export const galleryCardImgs = {
    0: {
        imgUrl: Img3,
        title: "Text 3"
    },
    1: {
        imgUrl: Img2,
        title: "Text 2"
    },
    2: {
        imgUrl: Img1,
        title: "Text 1"
    },
}





export const galleryCardNewsImgs = {
    0: {
        imgUrl: News1,
        title: "Text 1"
    },
    1: {
        imgUrl: News3,
        title: "Text 3"
    },

    2: {
        imgUrl: News8,
        title: "Text 4"
    },
    3: {
        imgUrl: News10,
        title: "Text 4"
    },
}



export const galleryCardOfficeImgs = {
    0: {
        imgUrl: Team3,
        title: "Text 3"
    },
    1: {
        imgUrl: Team2,
        title: "Text 2"
    },
    2: {
        imgUrl: Team1,
        title: "Text 1"
    },
    3: {
        imgUrl: Team4,
        title: "Text 4"
    },
    4: {
        imgUrl: Team5,
        title: "Text 4"
    },
    5: {
        imgUrl: OfficeImg10,
        title: "Text 4"
    },
    6: {
        imgUrl: OfficeImg1,
        title: "Text 4"
    },
    
    7: {
        imgUrl: OfficeImg3,
        title: "Text 4"
    },
    8: {
        imgUrl: OfficeImg4,
        title: "Text 4"
    },
    9: {
        imgUrl: OfficeImg5,
        title: "Text 4"
    },
    10: {
        imgUrl: OfficeImg6,
        title: "Text 4"
    },
    11: {
        imgUrl: OfficeImg7,
        title: "Text 4"
    },
    12: {
        imgUrl: OfficeImg8,
        title: "Text 4"
    },
}

export const galleryCardCertficatesImgs = {
    0: {
        imgUrl: Cert1,
        title: "Text 4"
    },
    1: {
        imgUrl: Cert2,
        title: "Text 4"
    },
    2: {
        imgUrl: Cert3,
        title: "Text 4"
    },
    3: {
        imgUrl: Cert4,
        title: "Text 4"
    },
    4: {
        imgUrl: Cert5,
        title: "Text 4"
    },
    5: {
        imgUrl: Cert6,
        title: "Text 4"
    },
}